import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Home1 from '../components/home1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Aswin Dev S</title>
        <meta property="og:title" content="Aswin Dev S" />
      </Helmet>
      <Navbar8
        text={
          <Fragment>
            <span className="home-text10">Aswin Dev S</span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="home-text11 thq-link thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="home-text12 thq-link thq-body-small">
              <span>Blogs</span>
              <br></br>
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="home-text15 thq-link thq-body-small">Gallery</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="home-text16 thq-link thq-body-small">
              /contact
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="home-text17 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="home-text18 thq-body-large">About</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="home-text19 thq-body-large">Blog</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="home-text20 thq-body-large">Contact</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="home-text21 thq-link thq-body-small">About</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text22">Subscribe</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="home-text23">Send Message</span>
          </Fragment>
        }
        link2Url="/blog"
        link3Url="/gallery"
        link3Url1="/"
      ></Navbar8>
      <Home1
        content1={
          <Fragment>
            <span className="home-text28 thq-body-large">
              <span className="home-text29">ENGINEER  | CHAOTIC | LEARNER  | WANDERLUST |  </span>
              <br></br>
              <br></br>
              <span className='home-text28'>
                "The more I learn, the more I realize how much I don't know."   -  Anonymous</span>
              <br></br>
              <br></br>
              <span>  
                Welcome to my vibrant and captivating world, where the thrill of learning and exploration fuels my passion. I embrace the excitement of new discoveries and the rush of adrenaline as I dive into the realms of technology and travel. Through striking, colorful, and vivid visual language, I invite you to experience my journey—a world where technology meets adventure, and every moment is an opportunity to capture the beauty of innovation and exploration.
              </span>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <span className="home-text30">
                I&apos;m a DevOps Engineer with over 3 years of hands-on experience in designing, implementing, and maintaining CI/CD
                pipelines, cloud infrastructure, and container orchestration
                systems. Passionate about automating everything to improve
                software delivery and operational efficiency.

              <br></br>
              <br></br>

              <h2>🌟 Highlights</h2>
              <ul>
                  <li><strong>AWS & GCP:</strong> Proficient in setting up, configuring, and optimizing cloud services.</li>
                  <li><strong>Platform Engineering:</strong> Working on a platform to spin up ephemeral environment on demand </li>
                <li><strong>Coding:</strong> Skilled in Python, Golang, and Shell scripting for automation and tool development.</li>
                <li><strong>Infrastructure as Code:</strong> Skilled in managing infrastructure using Terraform and CloudFormation.</li>
                <li><strong>Kubernetes & Istio:</strong> Experienced in deploying and managing microservices on Kubernetes with Istio for service mesh.</li>
                <li><strong>GitOps:</strong> Implemented GitOps practices using ArgoCD for automated application deployment and management.</li>
                <li><strong>CI/CD:</strong> Designed and maintained CI/CD pipelines using Jenkins, GitLab CI/CD, and GitHub Actions.</li>
                <li><strong>Containerization:</strong> Utilized Docker for containerization and orchestration of applications.</li>

                </ul>
              </span>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text36 thq-heading-1">
              👋 Hello, I&apos;m Dev !
            </span>
          </Fragment>
        }
        rootClassName="home1root-class-name"
      ></Home1>
    </div>
  )
}

export default Home
