import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Gallery1 from '../components/gallery1'
import Gallery3 from '../components/gallery3'
import Gallery4 from '../components/gallery4'
import './gallery.css'

const Gallery = (props) => {
  return (
    <div className="gallery-container">
      <Helmet>
        <title>Gallery - Aswin Dev S</title>
        <meta
          property="og:title"
          content="Gallery - Aswin Dev S"
        />
      </Helmet>
      <Navbar8
        text={
          <Fragment>
            <span className="gallery-text10">Aswin Dev S</span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="gallery-text11 thq-link thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="gallery-text12 thq-link thq-body-small">
              <span>Blogs</span>
              <br></br>
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="gallery-text15 thq-link thq-body-small">
              Gallery
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="gallery-text16 thq-link thq-body-small">
              /contact
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="gallery-text17 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="gallery-text18 thq-body-large">About</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="gallery-text19 thq-body-large">Blog</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="gallery-text20 thq-body-large">Contact</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="gallery-text21 thq-link thq-body-small">
              About
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="gallery-text22">Subscribe</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="gallery-text23">Send Message</span>
          </Fragment>
        }
        link2Url="/blog"
        link3Url="/gallery"
        link3Url1="/"
        rootClassName="navbar8root-class-name1"
        page1Description={
          <Fragment>
            <span className="gallery-text24 thq-body-small">
              Welcome to my personal blog site
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="gallery-text25 thq-body-small">
              Get to know more about me
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="gallery-text26 thq-body-small">
              Read my latest blog posts
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="gallery-text27 thq-body-small">
              Reach out to me for collaborations or inquiries
            </span>
          </Fragment>
        }
      ></Navbar8>
      <Gallery1
        heading1={
          <Fragment>
            <span className="gallery-text28 thq-heading-1">
              Explore Dev&apos;s Gallery
            </span>
          </Fragment>
        }
        rootClassName="gallery1root-class-name"
      ></Gallery1>
      <Gallery4
        heading1={
          <Fragment>
            <span className="gallery-text30 thq-heading-2">
              Spiti Gallery
            </span>
          </Fragment>
        }
      ></Gallery4>
    </div>
  )
}

export default Gallery
