import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import Banner1 from '../components/banner1'
import Blog1 from '../components/blog1'
import Blog2 from '../components/blog2'
import Blog3 from '../components/blog3'
import Blog4 from '../components/blog4'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container">
      <Helmet>
        <title>Blog - Aswin Dev S</title>
        <meta property="og:title" content="Blog - Aswin Dev S" />
      </Helmet>
      <Navbar8
        text={
          <Fragment>
            <span className="blog-text10">Aswin Dev S</span>
          </Fragment>
        }
        link1={
          <Fragment>
            <span className="blog-text11 thq-link thq-body-small">Home</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="blog-text12 thq-link thq-body-small">
              <span>Blogs</span>
              <br></br>
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="blog-text15 thq-link thq-body-small">Gallery</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="blog-text16 thq-link thq-body-small">
              /contact
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="blog-text17 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="blog-text18 thq-body-large">About</span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="blog-text19 thq-body-large">Blog</span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="blog-text20 thq-body-large">Contact</span>
          </Fragment>
        }
        link31={
          <Fragment>
            <span className="blog-text21 thq-link thq-body-small">About</span>
          </Fragment>
        }
      ></Navbar8>
      <Banner1
        heading1={
          <Fragment>
            <span className="blog-text28 thq-heading-2">Blog in Progress</span>
          </Fragment>
        }
      ></Banner1>
      <div className="blog-in-progress"></div>
      <h2>Stay tuned for more exciting blog posts!</h2>
      </div>
  )
}

export default Blog
