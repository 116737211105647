import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery4.css'

const Gallery4 = (props) => {
  return (
    <div className="gallery4-gallery3 thq-section-padding">
      <div className="gallery4-max-width thq-section-max-width">
        <div className="gallery4-section-title">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="gallery4-text2 thq-heading-2">
                  Spiti Gallery
                </h2>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="gallery4-container1">
          <div className="gallery4-content">
            <div
              data-thq="slider"
              data-navigation="true"
              data-pagination="true"
              className="gallery4-slider swiper"
            >
              <div
                data-thq="slider-wrapper"
                className="gallery4-slider-wrapper swiper-wrapper"
              >
                <div
                  data-thq="slider-slide"
                  className="gallery4-slider-slide1 swiper-slide"
                >
                  <div className="gallery4-container2">
                    <img
                      alt={props.image1Alt}
                      src={props.image1Src}
                      className="gallery4-image1 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery4-container3">
                    <img
                      alt={props.image2Alt}
                      src={props.image2Src}
                      className="gallery4-image2 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
                <div
                  data-thq="slider-slide"
                  className="gallery4-slider-slide2 swiper-slide"
                >
                  <div className="gallery4-container4">
                    <img
                      alt={props.image3Alt}
                      src={props.image3Src}
                      className="gallery4-image3 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery4-container5">
                    <img
                      alt={props.image4Alt}
                      src={props.image4Src}
                      className="gallery4-image4 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
                <div
                  data-thq="slider-slide"
                  className="gallery4-slider-slide3 swiper-slide"
                >
                  <div className="gallery4-container6">
                    <img
                      alt={props.image5Alt}
                      src={props.image5Src}
                      className="gallery4-image5 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery4-container7">
                    <img
                      alt={props.image6Alt}
                      src={props.image6Src}
                      className="gallery4-image6 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="swiper-button-next"
              ></div>
              <div
                data-thq="slider-pagination"
                className="gallery4-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery4.defaultProps = {
  image4Alt: 'Artwork 4',
  image5Alt: 'Artwork 5',
  heading1: undefined,
  image4Src:
    '/gallery/17.jpeg?q=80&w=8000',
  image6Src:
    '/gallery/16.jpeg',
  image5Src:
    '/gallery/13.jpeg',
  image3Src:
    '/gallery/19.jpeg',
  image6Alt: 'Artwork 6',
  image2Alt: 'Artwork 2',
  image2Src:
    '/gallery/8.jpeg',
  image1Src:
    '/gallery/20.jpeg',
  image3Alt: 'Artwork 3',
  image1Alt: 'Artwork 1',
}

Gallery4.propTypes = {
  image4Alt: PropTypes.string,
  image5Alt: PropTypes.string,
  heading1: PropTypes.element,
  image4Src: PropTypes.string,
  image6Src: PropTypes.string,
  image5Src: PropTypes.string,
  image3Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image1Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Gallery4
