import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './blog1.css'

const Blog1 = (props) => {
  return (
    <div
      className={`blog1-layout349 thq-section-padding ${props.rootClassName} `}
    >
      <div className="blog1-max-width thq-section-max-width">
        <div className="blog1-container">
          <img
            alt={props.feature1ImageAlt}
            src={props.feature1ImageSrc}
            className="blog1-placeholder-image thq-img-ratio-16-9"
          />
          <div className="blog1-image-container"></div>
        </div>
        <div className="blog1-content1">
          <div className="blog1-section-title">
            <div className="blog1-content2">
              <h2>
                {props.feature1Title ?? (
                  <Fragment>
                    <h2 className="thq-heading-2">Art Gallery</h2>
                  </Fragment>
                )}
              </h2>
              <p>
                {props.feature1Description ?? (
                  <Fragment>
                    <p className="thq-body-large">
                      Explore a vibrant gallery of Dev&apos;s latest artworks
                      and creations.
                    </p>
                  </Fragment>
                )}
              </p>
            </div>
            <div className="blog1-actions">
              <button className="blog1-button thq-button-outline">
                <span>
                  {props.feature1Action2 ?? (
                    <Fragment>
                      <span className="thq-body-small">Read More</span>
                    </Fragment>
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Blog1.defaultProps = {
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1655416571243-f0a14e25e15f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDQ3ODkwNnw&ixlib=rb-4.0.3&q=80&w=500',
  feature1Action2: undefined,
  feature1Description: undefined,
  feature1ImageAlt: 'Art Gallery Image',
  feature1Title: undefined,
  rootClassName: '',
}

Blog1.propTypes = {
  feature1ImageSrc: PropTypes.string,
  feature1Action2: PropTypes.element,
  feature1Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature1Title: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Blog1
