import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import Logos7 from './logos7'
import './home1.css'

const Home1 = (props) => {
  return (
    <div className={`home1-container ${props.rootClassName} `}>
      <div className="home1-column thq-section-padding">
        <div className="home1-content">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="home1-text11 thq-heading-1">
                  👋 Hello, I&apos;m Dev !
                </h1>
              </Fragment>
            )}
          </h1>
          <p>
            {props.content1 ?? (
              <Fragment>
                <p className="home1-text12 thq-body-large">
                  <span className="home1-text13">DEVOPS| PHOTOGRAPHY</span>
                  <br></br>
                  <span>
                    Welcome to my vibrant and captivating art world. I’m a
                    Uruguayan artist living in Denmark whose art rises from the
                    search for self-definition and understanding the essence of
                    life itself. Through striking, colorful, and clear visual
                    language my work captures the collective human experience
                    and reveals its universality through personal narrative
                    exploration. Explore my gallery and dive into my universe!
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    I&apos;m a DevOps Engineer with over 3 years of hands-on
                    experience in designing, implementing, and maintaining CI/CD
                    pipelines, cloud infrastructure, and container orchestration
                    systems. Passionate about automating everything to improve
                    software delivery and operational efficiency.
                  </span>
                  <br></br>
                </p>
              </Fragment>
            )}
          </p>
        </div>
        <img
          alt={props.image1Alt}
          src={props.image1Src}
          className="home1-image1"
        />
        <br></br>
        <br></br>
        <br></br> {/* Add an extra line break */}
      </div>
      <br></br>
      <img alt={props.imageAlt} src={props.imageSrc} className="home1-image" />
      <Logos7
        heading1={
          <Fragment>
            <span className="home1-text10 thq-heading-2">Tech Stack </span>
          </Fragment>
        }
        rootClassName="logos7root-class-name"
      ></Logos7>
    </div>
  )
}

Home1.defaultProps = {
  imageSrc: "/1000064119-01.jpeg",
  rootClassName: '',
  image1Alt: 'Artist creating vibrant art',
  image1Src:
    'https://images.unsplash.com/photo-1531938716357-224c16b5ace3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDd8fHRyYXZlbCUyMGFuZCUyMHRlY2h8ZW58MHx8fHwxNzI0NDgyODEyfDA&ixlib=rb-4.0.3&h=1000',
  imageAlt: 'image',
  heading1: undefined,
  content1: undefined,
}

Home1.propTypes = {
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  image1Alt: PropTypes.string,
  image1Src: PropTypes.string,
  imageAlt: PropTypes.string,
  heading1: PropTypes.element,
  content1: PropTypes.element,
}

export default Home1
