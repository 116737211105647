import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './gallery3.css'

const Gallery3 = (props) => {
  return (
    <div className="gallery3-gallery3 thq-section-padding">
      <div className="gallery3-max-width thq-section-max-width">
        <div className="gallery3-section-title">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="gallery3-text2 thq-heading-2">Spiti valley </h2>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="gallery3-container">
          <div className="gallery3-content">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery3-image1 thq-img-ratio-4-6"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery3-image2 thq-img-ratio-4-6"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery3-image6 thq-img-ratio-4-6"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="gallery3-image7 thq-img-ratio-4-6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery3.defaultProps = {
  heading1: undefined,
  image4Alt: 'Mixed Media Piece',
  image1Src:
    'https://images.unsplash.com/photo-1631624215749-b10b3dd7bca7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDQ4NzIwMXw&ixlib=rb-4.0.3&q=80&w=1400',
  image1Alt: 'Abstract Painting',
  image3Alt: 'Digital Art',
  image2Src:
    'https://images.unsplash.com/photo-1711747707922-3459779ad842?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDQ4NzIwMnw&ixlib=rb-4.0.3&q=80&w=1400',
  image3Src:
    'https://images.unsplash.com/photo-1578926314433-e2789279f4aa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDQ4NzIwM3w&ixlib=rb-4.0.3&q=80&w=1400',
  image2Alt: 'Sculpture',
  image4Src:
    'https://images.unsplash.com/photo-1445633743309-b60418bedbf2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNDQ4NzIwMnw&ixlib=rb-4.0.3&q=80&w=1400',
}

Gallery3.propTypes = {
  heading1: PropTypes.element,
  image4Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image3Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image4Src: PropTypes.string,
}

export default Gallery3
