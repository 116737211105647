import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './logos7.css'

const Logos7 = (props) => {
  return (
    <div
      className={`logos7-container10 thq-section-padding ${props.rootClassName} `}
    >
      <div className="logos7-max-width thq-section-max-width">
        <div className="logos7-container11">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="logos7-text2 thq-heading-2">Tech Stack</h2>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="logos7-container12">
          <div className="logos7-container13">
            <img
              alt={props.logo1Alt}
              src={props.logo1Src}
              className="logos7-logo110 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container14">
            <img
              alt={props.logo1Alt20}
              src={props.logo1Src20}
              className="logos7-logo111 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container15">
            <img
              alt={props.logo1Alt19}
              src={props.logo1Src19}
              className="logos7-logo112 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container16">
            <img
              alt={props.logo1Alt18}
              src={props.logo1Src18}
              className="logos7-logo113 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container17">
            <img
              alt={props.logo1Alt17}
              src={props.logo1Src17}
              className="logos7-logo114 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container18">
            <img
              alt={props.logo1Alt16}
              src={props.logo1Src16}
              className="logos7-logo115 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container19">
            <img
              alt={props.logo1Alt15}
              src={props.logo1Src15}
              className="logos7-logo116 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container20">
            <img
              alt={props.logo1Alt14}
              src={props.logo1Src14}
              className="logos7-logo117 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container21">
            <img
              alt={props.logo1Alt13}
              src={props.logo1Src13}
              className="logos7-logo118 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container22">
            <img
              alt={props.logo1Alt12}
              src={props.logo1Src12}
              className="logos7-logo119 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container23">
            <img
              alt={props.logo1Alt11}
              src={props.logo1Src11}
              className="logos7-logo120 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container24">
            <img
              alt={props.logo1Alt10}
              src={props.logo1Src10}
              className="logos7-logo121 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container25">
            <img
              alt={props.logo1Alt9}
              src={props.logo1Src9}
              className="logos7-logo122 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container26">
            <img
              alt={props.logo1Alt8}
              src={props.logo1Src8}
              className="logos7-logo123 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container27">
            <img
              alt={props.logo1Alt7}
              src={props.logo1Src7}
              className="logos7-logo124 thq-img-ratio-16-9"
            />
          </div>
          <div className="logos7-container28">
            <img
              alt={props.logo1Alt6}
              src={props.logo1Src6}
              className="logos7-logo125 thq-img-ratio-16-9"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Logos7.defaultProps = {
  logo1Alt14: "Artist's Logo",
  logo1Src19: '/pngwing.com%20(4)-700w.png',
  logo1Src17: '/pngwing.com%20(6)-700w.png',
  logo1Src10: '/pngwing.com%20(10)1-700w.png',
  logo1Src3:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original',
  logo1Src5:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original',
  logo1Alt19: "Artist's Logo",
  logo1Alt: "Artist's Logo",
  logo1Src1:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original',
  logo1Alt2: "Artist's Logo",
  logo1Alt1: "Artist's Logo",
  logo1Src16: '/pngwing.com%20(7)-700w.png',
  logo1Alt16: "Artist's Logo",
  heading1: undefined,
  logo1Src11: '/pngwing.com%20(11)-700w.png',
  logo1Alt12: "Artist's Logo",
  logo1Alt8: "Artist's Logo",
  logo1Alt13: "Artist's Logo",
  rootClassName: '',
  logo1Alt10: "Artist's Logo",
  logo1Src9: '/logo-700w.png',
  logo1Alt5: "Artist's Logo",
  logo1Src14: '/pngwing.com%20(9)-700w.png',
  logo1Alt7: "Artist's Logo",
  logo1Alt4: "Artist's Logo",
  logo1Src7: '/1_lydfawy_hjjw8lgcsi1iqg-700w.png',
  logo1Src18: '/pngwing.com%20(5)-700w.png',
  logo1Src15: '/pngwing.com%20(8)-700w.png',
  logo1Alt11: "Artist's Logo",
  logo1Src6: '/pngaaa.com-4882357-700w.png',
  logo1Alt9: "Artist's Logo",
  logo1Src2:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original',
  logo1Src4:
    'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/3c22ad70-97c1-42ae-9e5e-7f9cfdb33faa?org_if_sml=1&force_format=original',
  logo1Src8: '/pngwing.com%20(14)-700w.png',
  logo1Src: '/pngwing.com%20(2)-700w.png',
  logo1Src12: '/pngwing.com%20(12)-700w.png',
  logo1Alt17: "Artist's Logo",
  logo1Src20: '/pngwing.com%20(3)-700w.png',
  logo1Alt18: "Artist's Logo",
  logo1Alt15: "Artist's Logo",
  logo1Src13: '/pngwing.com%20(13)-700w.png',
  logo1Alt20: "Artist's Logo",
  logo1Alt3: "Artist's Logo",
  logo1Alt6: "Artist's Logo",
}

Logos7.propTypes = {
  logo1Alt14: PropTypes.string,
  logo1Src19: PropTypes.string,
  logo1Src17: PropTypes.string,
  logo1Src10: PropTypes.string,
  logo1Src3: PropTypes.string,
  logo1Src5: PropTypes.string,
  logo1Alt19: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo1Src1: PropTypes.string,
  logo1Alt2: PropTypes.string,
  logo1Alt1: PropTypes.string,
  logo1Src16: PropTypes.string,
  logo1Alt16: PropTypes.string,
  heading1: PropTypes.element,
  logo1Src11: PropTypes.string,
  logo1Alt12: PropTypes.string,
  logo1Alt8: PropTypes.string,
  logo1Alt13: PropTypes.string,
  rootClassName: PropTypes.string,
  logo1Alt10: PropTypes.string,
  logo1Src9: PropTypes.string,
  logo1Alt5: PropTypes.string,
  logo1Src14: PropTypes.string,
  logo1Alt7: PropTypes.string,
  logo1Alt4: PropTypes.string,
  logo1Src7: PropTypes.string,
  logo1Src18: PropTypes.string,
  logo1Src15: PropTypes.string,
  logo1Alt11: PropTypes.string,
  logo1Src6: PropTypes.string,
  logo1Alt9: PropTypes.string,
  logo1Src2: PropTypes.string,
  logo1Src4: PropTypes.string,
  logo1Src8: PropTypes.string,
  logo1Src: PropTypes.string,
  logo1Src12: PropTypes.string,
  logo1Alt17: PropTypes.string,
  logo1Src20: PropTypes.string,
  logo1Alt18: PropTypes.string,
  logo1Alt15: PropTypes.string,
  logo1Src13: PropTypes.string,
  logo1Alt20: PropTypes.string,
  logo1Alt3: PropTypes.string,
  logo1Alt6: PropTypes.string,
}

export default Logos7
