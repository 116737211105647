import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import Gallery2 from './gallery2'
import './gallery1.css'

const Gallery1 = (props) => {
  return (
    <div className={`gallery1-header78 ${props.rootClassName} `}>
      <div className="gallery1-column thq-section-padding thq-section-max-width">
        <div className="gallery1-content1">
          <h1>
            {props.heading1 ?? (
              <Fragment>
                <h1 className="gallery1-text3 thq-heading-1">
                  Explore Dev&apos;s Gallery
                </h1>
              </Fragment>
            )}
          </h1>
        </div>
        <div className="gallery1-actions"></div>
      </div>
      <div className="gallery1-content2">
        <div className="gallery1-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery1-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery1-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery1-placeholder-image12 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="gallery1-placeholder-image13 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="gallery1-placeholder-image14 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="gallery1-placeholder-image15 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery1-placeholder-image16 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery1-placeholder-image17 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery1-placeholder-image18 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="gallery1-placeholder-image19 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="gallery1-placeholder-image20 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1200"
              className="gallery1-placeholder-image21 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
        <div className="gallery1-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="gallery1-placeholder-image22 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="gallery1-placeholder-image23 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="gallery1-placeholder-image24 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="gallery1-placeholder-image25 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="gallery1-placeholder-image26 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image12Alt}
              src={props.image12Src}
              className="gallery1-placeholder-image27 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="gallery1-placeholder-image28 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image8Alt}
              src={props.image8Src}
              className="gallery1-placeholder-image29 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image9Alt}
              src={props.image9Src}
              className="gallery1-placeholder-image30 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image10Alt}
              src={props.image10Src}
              className="gallery1-placeholder-image31 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt={props.image11Alt}
              src={props.image11Src}
              className="gallery1-placeholder-image32 thq-img-ratio-1-1 thq-img-scale"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1200"
              className="gallery1-placeholder-image33 thq-img-ratio-1-1 thq-img-scale"
            />
          </div>
        </div>
      </div>
      <Gallery2
        heading1={
          <Fragment>
            <span className="gallery1-text2 thq-heading-2">Kashmir</span>
          </Fragment>
        }
      ></Gallery2>
      <div>
        <div className="gallery1-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Gallery1.defaultProps = {
  image9Alt: 'Hero Image',
  image6Alt: 'Hero Image',
  image2Src:
    '/gallery/10.jpeg',
  image7Src:
    '/gallery/9.jpeg',
  image10Alt: 'Hero Image',
  image6Src:
    '/gallery/8.jpeg',
  image10Src:
    '/gallery/7.jpeg',
  image5Alt: 'Hero Image',
  image7Alt: 'Hero Image',
  image12Src:
    '/gallery/6.jpeg',
  heading1: undefined,
  image12Alt: 'Hero Image',
  image5Src:
    '/gallery/5.jpeg',
  image4Alt: 'Hero Image',
  image4Src:
    '/gallery/4.jpeg',
  image8Src:
    '/gallery/11.jpeg',
  image11Src:
    '/gallery/2.jpeg',
  image11Alt: 'Hero Image',
  image3Src:
    '/gallery/12.jpeg',
  image1Src:
    '/gallery/9.jpeg',
  image3Alt: 'Hero Image',
  image9Src:
    '/gallery/6.jpeg',
  image1Alt: "Dev's vibrant artwork",
  rootClassName: '',
  image8Alt: 'Hero Image',
  image2Alt: 'Hero Image',
}

Gallery1.propTypes = {
  image9Alt: PropTypes.string,
  image6Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image7Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image10Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image7Alt: PropTypes.string,
  image12Src: PropTypes.string,
  heading1: PropTypes.element,
  image12Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image4Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image8Src: PropTypes.string,
  image11Src: PropTypes.string,
  image11Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image1Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image9Src: PropTypes.string,
  image1Alt: PropTypes.string,
  rootClassName: PropTypes.string,
  image8Alt: PropTypes.string,
  image2Alt: PropTypes.string,
}

export default Gallery1
